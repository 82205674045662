import { Block } from 'baseui/block';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';
import 'theme/layout.css';
import { useStyletron } from 'baseui';
import { Grid, Cell } from 'baseui/layout-grid';
import { Menu } from 'baseui/icon';
import { Drawer, ANCHOR, SIZE } from 'baseui/drawer';
import LeftNav from '../sidebar/leftNav';
import Topbar from '../topbar';

const Outer = ({ isMiniLoaderActive, children }) => {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        background: theme.colors.primary50,
        // paddingTop: theme.sizing.scale700,
        paddingBottom: 0,
        // padding: theme.sizing.scale700,
        height: '100vh',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column'
      })}
    >
      <div
        className={css({
          width: '100%',
          position: 'inherit'
        })}
      >
        <Topbar isMiniLoaderActive={isMiniLoaderActive} />
      </div>
      <div
        id='app-container'
      >{children}</div>
    </div>
  );
};

const Layout = ({ isMiniLoaderActive, children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [css, theme] = useStyletron();
  return (
    <>
      <Block>
        <div
          style={{
            position: 'absolute',
            right: '43px',
            top: '10px',
            zIndex: 100,
            color: 'white',
          }}
          className="draw-menu-icon"
        >
          <Menu size={32} onClick={() => setIsOpen(true)} />
        </div>
        <Grid
          gridMaxWidth={'unset'}
          overrides={{
            Grid: {
              style: () => ({
                padding: '0px !important',
                overflow: 'hidden',
              }),
            },
          }}
        >
          <Cell
            span={[2.2]}
            overrides={{
              Cell: {
                style: () => ({
                  padding: '0px !important',
                  height: '100vh',
                }),
                props: old => ({
                  ...old,
                  className: 'side-bar-container',
                }),
              },
            }}
          >
            <LeftNav />
          </Cell>
          <Cell
            span={[9.8]}
            overrides={{
              Cell: {
                style: () => ({
                  padding: '0px !important',
                  height: '100%'
                }),
              },
            }}
          >
            <Outer isMiniLoaderActive={isMiniLoaderActive}>
              <main id="main">{children}</main>
            </Outer>
          </Cell>
        </Grid>
      </Block>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
