import * as React from 'react';
import { connect } from 'react-redux';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { navigate } from '@reach/router';
import styled from '@emotion/styled';
import { setBreadCrumb } from '../../redux/actions/appBasicControls';
import { menuOptions, menuOptionsV1 } from './options';
import _ from 'lodash';
import logo from './sideNavIcons/horizontal_cardwarelogo_gradient_white_text.svg';
import { gridPaddingOverrides } from '../overrides';
import { getProfile } from '../../auth/index';
import Zendesk from "react-zendesk";
import { getServerUrl } from '../../apollo/client';
import store from '../../redux/store';
const config = require('../../../data/config');


const OptionMenuEleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 66px;
  color: white;
  cursor: pointer;
`;

const OptionMenuEleText = styled.p`
  max-width: 68%;
  margin-bottom: 0px; 
  margin-left: 0.5vw; 
  font-weight: ${props => props.fontWeight};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  color: ${props => props.color};
`
const LeftNavContainer = styled.div`
  background: ${props => props.theme.leftNavBackgroundColor};
  height: 100%;
  min-height: 100vh;
  cursor: pointer;
`

const LogoContainer = styled.div`
  height: 59px;
  position: relative;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-bottom: 86px;
`
const VersionText = styled.p`
  color: #FFFFFF66;
  font-size: 9px;
  margin-bottom: 0px;
  width: 80%;
  padding-left: 10px;
  line-height: 11px;
  margin-top: 9px;
`
const LeftNav = props => {
  const { selectedChannel, selectedGroup, dispatch, channelGroups, channels, organization_settings } = props;
  const { is_library_nav_on, is_rewards_nav_on, is_api_settings_on } = organization_settings || {};
  var orgId = '';
  let path = window.location.pathname;
  let orgIdMatch = path.match(
    /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/
  );
  if (orgIdMatch) {
    orgId = orgIdMatch[0];
  }
  let selectedByPath = menuOptions.find(el => {
    if (!el.path) return false;
    if (typeof window !== `undefined`) {
      let op1 = path
        .replace(
          /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/g,
          ':id'
        )
        .split('/')
        .filter(el => !el.includes(':'));
      let op2 = el.path.split('/').filter(el => !el.includes(':'));

      const isRewardNavOption = op1.includes('reward') && op2.includes('reward');
      return _.isEqual(op1, op2) || isRewardNavOption;
    }
    return false;
  });
  selectedByPath = selectedByPath ? selectedByPath.key : null;
  const [selected, setSelected] = React.useState(selectedByPath);

  const [css, theme] = useStyletron();
  const iconsCss = css({
    // left: '50%',
    // top: '50%',
    width: '25px',
    height: '25px',
    // position: 'relative',
    // transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    marginBottom: '0px',
  });

  const mainDivCss = (isSelected, className) => {
    return {
      Cell: {
        style: () => ({
          // 'border': isSelected ? `1px solid ${theme.colors.accent}` : 'none',
          // 'borderRadius': '50%',
          width: '100%',
          height: '55px !important',
          marginBottom: '0px !important',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: isSelected ? 'black' : 'transparent',
          paddingLeft: '1.5vw !important',
          paddingRight: '0px !important',
          // paddingTop: theme.sizing.scale1600,
        }),
        props: {
          className: className
        }
      },
    };
  };

  const innerDivCss = isSelected =>
    css({
      // 'background': isSelected ? `${theme.colors.accent}`: 'transparent',
      borderRadius: '50%',
      width: '100% !important',
      // height: '75px !important',
      position: 'relative',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      float: 'left',
      paddingTop: '10px',
      paddingBottom: '10px',
    });

  const logoCss = css({
    // position: 'relative',
    width: '80%',
    // height: '19.2px',
    // top: '16px',
    // left: '50%',
    // transform: 'translateX(-50%)',
    paddingTop: '20px',
    objectFit: 'contain',
    // paddingBottom: '10px',
    marginBottom: '0px !important'
  });

  const profileCss = css({
    position: 'relative',
    height: '80px',
    /* float: right; */
    // bottom: '16px',
    left: '50%',
    width: '50%',
    height: '50%',
    transform: 'translateX(-50%)',
  });

  const selectedCss = css({
    position: 'absolute',
    width: '10px',
    height: '100%',
    right: '0',
    background: 'linear-gradient(180deg, rgb(52, 69, 236) 0%, #5D64AB 100%)',
  });

  const onClick = option => {
    if (props.onCloseMenu) {
      props.onCloseMenu(false);
    }
    if (option.key == selected) return true;
    if (option.onClick) {
      const state = store.getState().appBasicControls;
      let d = {
        api: getServerUrl() + '/graphql',
        organization_id: state.organization.id,
        user_id: state.user.idTokenPayload.app_metadata[`${state.organization.id}_admin_id`] || state.user.idTokenPayload.user_metadata.admin_id
      };
      console.log(d);
      return option.onClick(d);
    };
    setSelected(option.key);
    if (option.path) {
      let groupId = selectedGroup ? selectedGroup && selectedGroup.id : channelGroups[0] && channelGroups[0].id;
      const url = option.path
        .replace(':id', orgId)
        .replace(':groupId', groupId)
        .replace(
          ':channelId',
          selectedChannel
            ? selectedChannel.id
            : channels.length > 0
              ? channels[0].id
              : ''
        );
      const breadCrumb = {
        ...option,
        url,
      }
      dispatch(setBreadCrumb(breadCrumb))
      // if (option.key === 'builder') {
      //   const historyUrl = localStorage.getItem('router_history');
      //   console.log('History URL: ', historyUrl)
      //   navigate(historyUrl || url);
      //   localStorage.removeItem('router_history');
      //   return;
      // }
      navigate(url);
    }
  };

  // if (!selected) onClick(menuOptions.find((el) => el.isDefault));
  // console.log(selectedByPath);
  React.useEffect(() => {
    selectedByPath && setSelected(selectedByPath);
  }, [selectedByPath]);
  const profile = getProfile();
  const { given_name, picture, email } = profile.idTokenPayload
    ? profile.idTokenPayload
    : {};
  // console.log(given_name, picture, email);
  React.useEffect(() => {
    window.zESettings = {
      webWidget: {
        color: { theme: '#4a4ca0' }
      },
      contactForm: {
        fields: [
          { id: 'description', prefill: { '*': '' } },
          { id: 'name', prefill: { '*': given_name }, readOnly: true },
          { id: 'email', prefill: { '*': email }, readOnly: true }
        ]
      },
      identify: {
        name: given_name,
        email: email,
        organization_id: orgId
      }
    };
  }, []);

  return (
    <>
      <LeftNavContainer theme={theme}>
        <LogoContainer>
          <img src={logo} className={logoCss}></img>
          <VersionText>Version 3.1.10_6</VersionText>
        </LogoContainer>

        <Grid
          overrides={{
            Grid: {
              style: () => ({
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
                paddingBottom: '0px !important',
              }),
            },
          }}
        >
          {menuOptions.map(op => {
            if (!is_api_settings_on && op.key === 'stash')  return null;
            const icon = op.key == selected ? op.selectedIcon : op.icon;
            const iconTxt = op.title;
            if (op.key == 'rewards' && !is_rewards_nav_on) return <></>;
            if (op.key == 'library' && !is_library_nav_on) return <></>;
            return (
              <Cell
                span={12}
                overrides={mainDivCss(op.key == selected, op.key)}
                key={op.key}
              >
                <div
                  className={innerDivCss(op.key == selected)}
                  onClick={() => onClick(op)}
                >
                  <OptionMenuEleContainer >
                    <img src={icon} className={iconsCss} />
                    <OptionMenuEleText fontWeight={selected == op.key ? 700 : 600} color={selected == op.key ? theme.leftNavBar.selectedTextColor : theme.leftNavBar.unSelectedTextColor}>{iconTxt}</OptionMenuEleText>
                  </OptionMenuEleContainer>
                </div>
              </Cell>
            );
          })}
        </Grid>
        <Grid overrides={gridPaddingOverrides}>
          <Zendesk defer zendeskKey={config.zendeskKey} />
        </Grid>
      </LeftNavContainer>
    </>
  );
};

const mapStateToProps = props => {
  let { selectedChannel, selectedGroup, groups, channels, organization_settings } = props.appBasicControls;
  return {
    selectedChannel,
    selectedGroup,
    channelGroups: groups,
    channels,
    organization_settings
  };
};
export default connect(mapStateToProps)(LeftNav);
