import * as React from 'react';
import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';
import styled from '@emotion/styled';
import RenderIcon from '../icons/renderIcon';

const OverlayContainer = styled.div`
    background: rgba(32, 47, 62, 0.9);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100;
`;

const PredictiveContainer = styled.div`
    background: #ffffff;
    cursor: pointer;
    border-radius: 3px;
    left: 0;
    margin-top: 1px;
    max-height: 475px;
    overflow-y: auto;
    position: absolute;
    top: 42px;
    width: 100%;
    z-index: 101;

    @media (min-width) {
        max-height: 385px;
    }

    @medai (min-width: 1280px) {
        max-height: 413px;
    }
`;

const Card = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ErrorContainer = styled.div`
    background: #ffffff;
    border-radius: 2px;
    color: #242424;
    font-size: 0.8rem;
    line-height: 1.38;
    max-height: 102px;
    padding: 1rem;
    text-align: center;
    width: 100%;
`;

const CardItem = styled.li`
    // border-bottom: 1px solid #dadada;
    color: #0a0a0a;
    font-size: 15px;
    font-weight: normal;
    height: 60px;
    line-height: 1;
    letter-spacing: -0.15px;
    margin: 0;
    padding: 0 0.5rem;
    display: flex;
    justify-content: center;
    width: 100%;

    &:hover {
        background: #eaeaec;
    }
`;

const TypeItem = styled.span`
    float: right;
    background: rgba(0, 0, 0, 0) linear-gradient(rgb(118, 95, 237) 0%, rgb(230, 70, 162) 100%) repeat scroll 0% 0% !important;
    border-radius: 10px;
    font-size: 10px;
    color: white;
    padding: 5px;
`

export default ({ searchResult, searchPreviewData = [] }) => {
    const [searchText, setSearchText] = React.useState('');
    const [focus, setFocus] = React.useState(false)

    const searchOnChange = (e) => {
        setSearchText(e.target.value)
        searchResult(e.target.value)
    }

    const onFocus = (e) => {
        setFocus(true);
    }

    const onBlur = (e) => {
        setFocus(false);
    }

    const onClear = () => {
        setFocus(false);
        setSearchText("")
        searchResult("")
    }

    const searchPreview = (data) => {
        return (
            <PredictiveContainer>
                <Card>
                    {data.map((item) => {
                        const { title, img, type, icon_name } = item;
                        const fileData = img ? JSON.parse(img) : null;
                        let imageUrl;
                        if (fileData) {
                            imageUrl = fileData?.metadata?.cover_small_url;
                        }
                        return (
                            <CardItem onClick={() => {
                                setSearchText(title)
                                searchResult(title, item)
                                onBlur()
                            }}>
                                <div style={{ width: '93%', height: '100%', borderBottom: '1px solid #dadada', display: 'flex', alignItems: 'center' }}>
                                    {!icon_name && imageUrl && <img src={imageUrl} style={{ margin: '0 0.5rem 0 0', width: '40px', height: '40px' }} />}
                                    {icon_name && (
                                        <div style={{ margin: '0 0.5rem 0 0', position: 'relative', width: '40px', height: '40px' }}>
                                            <RenderIcon iconName={icon_name} searchIcon></RenderIcon>
                                        </div>
                                    )}
                                    <div style={{ marginLeft: (imageUrl || icon_name) ? 0 : '48px', width: '100%' }}>
                                        {title.length < 30 ? title : title.substring(0, 29)} <TypeItem>{type}</TypeItem>
                                    </div>
                                </div>
                            </CardItem>
                        )
                    })}
                </Card>
                {data.length == 0 && <ErrorContainer>No results found</ErrorContainer>}
            </PredictiveContainer>
        )
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '3px', position: 'relative' }}>
            <Search size={28} color={'white'} onClick={e => searchResult(searchText)} style={{ zIndex: focus ? 101 : 0 }} />
            <Input
                id={'search-box'}
                value={searchText}
                onChange={searchOnChange}
                placeholder="Search for categories, decks, cards and components"
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        searchResult(searchText)
                    }
                    if (e.key === "Escape") {
                        setSearchText("")
                        searchResult("")
                    }
                    return true;
                }}
                onFocus={e => onFocus(e)}
                overrides={{
                    Input: {
                        style: {
                            fontSize: '12px',
                            color: 'white',
                            background: 'transparent !important',
                            paddingLeft: '16px',
                            caretColor: 'white'
                        }
                    },
                    InputContainer: {
                        style: {
                            background: 'transparent !important',
                        }
                    },
                    Root: {
                        style: {
                            width: '333px',
                            padding: 0,
                            background: 'transparent !important',
                            border: '0 !important',
                            zIndex: focus ? 101 : 0
                        }
                    },
                    ClearIcon: {
                        style: {
                            background: '#E2E2E2 !important',
                            color: 'black'
                        }
                    },
                    ClearIconContainer: {
                        style: {
                            background: 'transparent !important',
                            color: 'black'
                        }
                    }
                }}
            />
            {focus && searchText && (
                <svg width="10" height="10"
                    onClick={onClear}
                    viewBox="0 0 10 10"
                    style={{ 'stroke': 'white', zIndex: focus ? 101 : 0, cursor: 'pointer' }}
                    mlns="http://www.w3.org/2000/svg"
                >
                    <path d="M9 1L5 5M1 9L5 5M5 5L1 1M5 5L9 9" stroke-width="2" stroke-linecap="round"></path>
                </svg>
            )}
            {focus && searchText && searchPreview(searchPreviewData)}
            {focus && <OverlayContainer onClick={() => onBlur()} />}
        </div>
    )
}