import * as React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { StatefulPopover } from "baseui/popover";
import {
  LabelSmall
} from 'baseui/typography';
import Button from '../styledButton';
import { useSubscription } from '@apollo/client';
import gql from 'graphql-tag';

const Outer = styled.div`
  width: 44px;
  height: 44px;
  position:relative;
`;

const ESTIMATED_BUILD_TIME = 6 * 60 * 1000;

const PWA_SUBSCRIPTION = gql`
  subscription onNewPwaApp($organization_id: ID!) {
    onNewPwaApp(organization_id: $organization_id) {
      is_building
      created_at
      app_url
      is_built
    }
  }
`;


const PwaAppBuildStatus = ({ organization_id }) => {
  const [progress, setProgress] = React.useState(0);
  const [isBuilding, setIsBuilding] = React.useState(false);
  const [appUrl, setAppUrl] = React.useState(null);
  const [appBuilt, setAppBuilt] = React.useState(true);

  const { data } = useSubscription(
    PWA_SUBSCRIPTION,
    { variables: { organization_id } },
  );


  const setProgressFromTime = (created_at) => {
    let t = new Date(created_at).getTime();
    let c = new Date().getTime();
    let elapsed = c - t;
    let p = elapsed / ESTIMATED_BUILD_TIME * 100;
    p = Math.ceil(p);
    if (p >= 99) p = 99;
    setProgress(p);
  };

  React.useEffect(() => {
    if (!data) return;
    let d = data.onNewPwaApp;
    setIsBuilding(d.is_building);
    setAppUrl(d.app_url);
    setProgressFromTime(d.created_at);
    setAppBuilt(d.is_built);
  }, [data]);


  if (!isBuilding) return <></>;

  return <StatefulPopover
    content={() => (
      <div style={{
        padding: '8px'
      }}>
        {appBuilt ? <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          <LabelSmall style={{
            marginRight: '16px'
          }}>
            App has been Built
        </LabelSmall>
          <Button isSelected marginTop={'0px'} onClick={() => {
            window.open(
              appUrl,
              '_blank'
            );
          }}>View App</Button>
        </div> : <LabelSmall >Your app is Building ({progress}%)</LabelSmall>}
      </div>
    )}
    returnFocus
    autoFocus
  >
    <Outer>
      <CircularProgressbarWithChildren
        value={progress}
        // text={`${progress}%`}
        background
        backgroundPadding={6}
        styles={buildStyles({
          backgroundColor: "#fff",
          textColor: "#000",
          pathColor: "#000",
          trailColor: "transparent"
        })}
      >
        <img src="https://img.icons8.com/external-phatplus-lineal-phatplus/64/000000/external-app-smartphone-phatplus-lineal-phatplus.png" style={{
          height: '22px',
          marginBottom: '0px',
          cursor: 'pointer'
        }} />
      </CircularProgressbarWithChildren>
    </Outer>
  </StatefulPopover>
};


export default PwaAppBuildStatus;