import ChannelsSvg from './sideNavIcons/channels.svg';
import BuilderSvg from './sideNavIcons/builder.svg';
import ConnectSvg from './sideNavIcons/connect.svg';
import DashboardSvg from './sideNavIcons/dashboard.svg';
import AdminsSvg from './sideNavIcons/admins.svg';
import SettingsSvg from './sideNavIcons/settings.svg';
import UsersSvg from './sideNavIcons/users.svg';
import EmergencySvg from './sideNavIcons/Emergency Tecnical Support.svg';

import ChannelsGradientSvg from './sideNavIcons/channels_gradient.svg';
import BuilderGradientSvg from './sideNavIcons/builder_gradient.svg';
import ConnectGradientSvg from './sideNavIcons/connect_gradient.svg';
import DashboardGradientSvg from './sideNavIcons/dashboard_gradient.svg';
import AdminsGradientSvg from './sideNavIcons/admins_gradient.svg';
import SettingsGradientSvg from './sideNavIcons/settings_gradient.svg';
import UsersGradientSvg from './sideNavIcons/users_gradient.svg';
import EmergencyGradientSvg from './sideNavIcons/Emergency Tecnical Support_gradient.svg';

import Library from './sideNavIcons/Library.svg'
import LibraryActive from './sideNavIcons/Library-active.svg';

import Rewards from './sideNavIcons/Rewards.svg';
import RewardsActive from './sideNavIcons/Rewards-active.svg';

import Templates from './sideNavIcons/Templates.svg';
import TemplatesActive from './sideNavIcons/Templates-active.svg';

import Coding from './sideNavIcons/coding.svg';
import CodingActive from './sideNavIcons/coding_gradient.svg';

export const menuOptions = [
  {
    icon: Library,
    selectedIcon: LibraryActive,
    key: 'apps',
    path: '/app/:id/app_list',
    tooltipContent: 'Apps',
    title: 'Apps'
  },
  {
    icon: BuilderSvg,
    selectedIcon: BuilderGradientSvg,
    key: 'builder',
    path: '/app/:id/groups/:groupId/channels',
    isDefault: true,
    tooltipContent: 'App Builder',
    title: 'Builder'
  },
  {
    icon: UsersSvg,
    selectedIcon: UsersGradientSvg,
    key: 'users',
    path: '/app/:id/basic',
    tooltipContent: 'Users',
    title: 'Users'
  },
  {
    icon: ConnectSvg,
    selectedIcon: ConnectGradientSvg,
    key: 'connect',
    path: '/app/:id/connect',
    tooltipContent: 'Connect',
    title: 'Connect'
  },
  {
    icon: DashboardSvg,
    selectedIcon: DashboardGradientSvg,
    key: 'dashboard',
    path: '/app/:id/dashboard',
    tooltipContent: 'Analytics',
    title: 'Analytics'
  },
  {
    icon: Coding,
    selectedIcon: CodingActive,
    key: 'stash',
    path: '/app/:id/stash',
    tooltipContent: 'Stash',
    title: 'Stash'
  },
  {
    icon: SettingsSvg,
    selectedIcon: SettingsGradientSvg,
    key: 'settings',
    path: '/app/:id/settings',
    tooltipContent: 'Settings',
    title: 'Settings'
  },
  // {
  //   icon: AdminsSvg,
  //   selectedIcon: AdminsGradientSvg,
  //   key: 'admins',
  //   path: '/app/:id/admins',
  //   tooltipContent: 'Admin',
  //   title: 'Admins',
  // },
  {
    icon: EmergencySvg,
    selectedIcon: EmergencyGradientSvg,
    key: 'emergency',
    tooltipContent: 'Support',
    title: 'Support',
    onClick: () => {
      window.open(
        `https://cardware.zendesk.com/hc/en-us`,
        '_blank'
      );
    }
  },
  {
    icon: Library,
    selectedIcon: LibraryActive,
    path: '/app/:id/library',
    key: 'library',
    tooltipContent: 'Library',
    title: 'Library',
  },
  {
    icon: Rewards,
    selectedIcon: RewardsActive,
    path: '/app/:id/reward/Requested',
    key: 'rewards',
    tooltipContent: 'Rewards',
    title: 'Rewards',
  },
  {
    icon: Templates,
    selectedIcon: TemplatesActive,
    path: '/app/:id/templates',
    key: 'templates',
    tooltipContent: 'Templates',
    title: 'Templates',
  },
  {
    icon: EmergencySvg,
    selectedIcon: EmergencyGradientSvg,
    key: 'emergency',
    tooltipContent: 'Support',
    title: 'Chat',
    onClick: (d) => {
      const refKey = new Date().getTime() | '';
      sessionStorage.setItem(refKey, JSON.stringify(d))
      window.open(
        `https://cardware-chat-v1.netlify.app/?ref=${JSON.stringify(d)}`,
        '_blank'
      );
    }
  }
];

export const menuOptionsV1 = [
  {
    icon: BuilderSvg,
    selectedIcon: BuilderGradientSvg,
    key: 'builder',
    path: '/app/:id/groups/:groupId/channels',
    isDefault: true,
    tooltipContent: 'App Builder',
    title: 'Projects',
    options: []
  },
  {
    icon: BuilderSvg,
    selectedIcon: BuilderGradientSvg,
    key: 'builder',
    path: '/app/:id/groups/:groupId/channels',
    isDefault: true,
    tooltipContent: 'App Builder',
    title: 'Manage',
    options: [
      {
        icon: BuilderSvg,
        selectedIcon: BuilderGradientSvg,
        key: 'builder',
        path: '/app/:id/groups/:groupId/channels',
        isDefault: true,
        tooltipContent: 'App Builder',
        title: 'Builder'
      },
      {
        icon: UsersSvg,
        selectedIcon: UsersGradientSvg,
        key: 'users',
        path: '/app/:id/basic',
        tooltipContent: 'Users',
        title: 'Users'
      },
      {
        icon: ConnectSvg,
        selectedIcon: ConnectGradientSvg,
        key: 'connect',
        path: '/app/:id/connect',
        tooltipContent: 'Connect',
        title: 'Connect'
      },
      {
        icon: DashboardSvg,
        selectedIcon: DashboardGradientSvg,
        key: 'dashboard',
        path: '/app/:id/dashboard',
        tooltipContent: 'Analytics',
        title: 'Analytics'
      },
      {
        icon: Coding,
        selectedIcon: CodingActive,
        key: 'stash',
        path: '/app/:id/stash',
        tooltipContent: 'Stash',
        title: 'Stash'
      },
      {
        icon: SettingsSvg,
        selectedIcon: SettingsGradientSvg,
        key: 'settings',
        path: '/app/:id/settings',
        tooltipContent: 'Settings',
        title: 'Settings'
      },
      {
        icon: EmergencySvg,
        selectedIcon: EmergencyGradientSvg,
        key: 'emergency',
        tooltipContent: 'Support',
        title: 'Support',
        onClick: () => {
          window.open(
            `https://cardware.zendesk.com/hc/en-us`,
            '_blank'
          );
        }
      },
      {
        icon: Library,
        selectedIcon: LibraryActive,
        path: '/app/:id/library',
        key: 'library',
        tooltipContent: 'Library',
        title: 'Library',
      },
      {
        icon: Rewards,
        selectedIcon: RewardsActive,
        path: '/app/:id/reward/Requested',
        key: 'rewards',
        tooltipContent: 'Rewards',
        title: 'Rewards',
      },
      {
        icon: Templates,
        selectedIcon: TemplatesActive,
        path: '/app/:id/templates',
        key: 'templates',
        tooltipContent: 'Templates',
        title: 'Templates',
      },
      {
        icon: EmergencySvg,
        selectedIcon: EmergencyGradientSvg,
        key: 'emergency',
        tooltipContent: 'Support',
        title: 'Chat',
        onClick: (d) => {
          const refKey = new Date().getTime() | '';
          sessionStorage.setItem(refKey, JSON.stringify(d))
          window.open(
            `https://cardware-chat-v1.netlify.app/?ref=${JSON.stringify(d)}`,
            '_blank'
          );
        }
      }
    ]
  }
]
