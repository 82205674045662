import * as React from 'react';
import { connect } from 'react-redux';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { navigate } from '@reach/router';
import { navigate as gatsbyNavigate } from 'gatsby';
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover';
import { StatefulMenu, NestedMenus } from 'baseui/menu';
import { useLocation } from '@reach/router';
import { debounce } from 'lodash';
import { toggleTheme, setSearch, setSearchPreview, setSelectedChannel, setSelectedGroup, setIsTourPopStatus } from '../../redux/actions/appBasicControls';
import { getProfile, logout } from '../../auth/index';
import { client } from '../../apollo/client';
import gql from 'graphql-tag';
import { switchToOrg } from '../../auth/index';
import styled from '@emotion/styled';
import { Button, KIND, SIZE } from 'baseui/button';
import { ChevronDown } from "baseui/icon";
import SearchCategory from '../category/searchCategory';
import { Icon } from 'react-icons-kit';
import { help } from 'react-icons-kit/ionicons/help';
import PwaAppBuildStatus from './pwa-app-build-status';
import {
  LabelXSmall
} from 'baseui/typography';

let search;
const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const SearchContainer = styled.div`
  display: flex; 
  flex: 1; 
  height: 100%;
  max-width: 40%;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  background:  tranparent;
`;
const Option = styled.div``;

const TopBar = props => {
  const {
    cards,
    dispatch,
    organization,
    defaultTheme,
    searchPreviewData,
    groups,
    decks,
    category,
    channels,
    questionCards,
    selectedGroup,
    selectedChannel,
    setIsTourPopupShown
  } = props;
  const [css, theme] = useStyletron();
  const [organizations, setOrganizations] = React.useState([]);
  const [currentOrg, setCurrentOrg] = React.useState('');
  const [isBuilderPage, setIsBuilderPage] = React.useState(true);
  const location = useLocation();
  React.useEffect(() => {
    setIsBuilderPage(typeof window !== 'undefined' ? window.location.pathname.includes('groups') : false);
  }, [location])
  const profileCss = css({
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    marginTop: '1px',
    marginBottom: '0',
  });

  const _setSelectedChannel = (group, channel) => {
    if (group && channel) {
      dispatch(setSelectedChannel(channel));
      dispatch(setSelectedGroup(group));
      navigate(
        `/app/${currentOrg}/groups/${group.id}/channels/${channel.id}`
      );
    }
  };

  const setSearchText = (filter, searchItem) => {
    if (!filter) {
      dispatch(setSearchPreview([]))
      dispatch(setSearch(""));
    }
    let keywords = [];
    cards.map(({ card, deck_id }) => {
      const { title, file_data, __typename, id } = card;
      if (title && title.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
        keywords.push({ title: title, img: file_data, type: __typename, id, deck_id })
      }
    });
    decks.map(({ deck_category_deck, deck_id }) => {
      const { title, cover_image_data, __typename, id, icon_name } = deck_category_deck;
      if (title && title.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
        keywords.push({ title: title, img: cover_image_data, icon_name, type: __typename, deck_id })
      }
    });
    groups.map(({ name, id, organization_id }) => {
      if (name && name.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
        keywords.push({ title: name, img: null, type: 'Group', id })
      }
    });
    channels.map(({ name, id, organization_id, app_content_scope_group_id }) => {
      if (name && name.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
        keywords.push({ title: name, img: null, type: 'Channel', id, app_content_scope_group_id })
      }
    });
    category.map(({ app_content_scope_id, category, category_id }) => {
      const { name, id, organization_id } = category;
      if (name && name.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
        keywords.push({ title: name, img: null, type: 'Category', category_id, app_content_scope_id })
      }
    })
    if (searchItem) {
      const { type } = searchItem
      if (type == 'Deck' || type == 'Card' || type == 'Category') {
        dispatch(setSearch(filter));
        if (type == 'Card' || type == 'Deck') {
          const { deck_id } = searchItem;
          const selectedCategoryId = decks.find(item => item.deck_id === deck_id)?.['category_id'];
          if (selectedCategoryId) {
            const selectedCategory = category.find(item => item.category_id === selectedCategoryId);
            const selectedChannelForSearch = channels.find(item => item.id === selectedCategory['app_content_scope_id']);
            if (!(selectedChannelForSearch && selectedChannel.id === selectedChannelForSearch['id'])) {
              const selectedGroupForSearch = groups.find(group => group.id === selectedChannelForSearch['app_content_scope_group_id'])
              _setSelectedChannel(selectedGroupForSearch, selectedChannelForSearch);
            }
          }
        } else if (type == 'Category') {
          const { category_id } = searchItem;
          if (category_id) {
            const selectedCategory = category.find(item => item.category_id === category_id);
            const selectedChannelForSearch = channels.find(item => item.id === selectedCategory['app_content_scope_id']);
            if (!(selectedChannelForSearch && selectedChannel.id === selectedChannelForSearch['id'])) {
              const selectedGroupForSearch = groups.find(group => group.id === selectedChannelForSearch['app_content_scope_group_id'])
              _setSelectedChannel(selectedGroupForSearch, selectedChannelForSearch);
            }
          }
        }
      } else if (type == 'Group' || type == 'Channel') {
        dispatch(setSearch(""));
        if (type === 'Channel') {
          const { id } = searchItem;
          const selectedChannelForSearch = channels.find(item => item.id === id);
          if (!(selectedChannelForSearch && selectedChannel.id === selectedChannelForSearch['id'])) {
            const selectedGroupForSearch = groups.find(group => group.id === selectedChannelForSearch['app_content_scope_group_id'])
            _setSelectedChannel(selectedGroupForSearch, selectedChannelForSearch);
          }
        } else if (type == 'Group') {
          const { id } = searchItem;
          const selectedGroupForSearch = groups.find(group => group.id === id)
          const selectedGroupChannels = channels.filter(c => c.app_content_scope_group_id == id);
          if (selectedGroupForSearch && selectedGroupChannels.length > 0) {
            _setSelectedChannel(selectedGroupForSearch, selectedGroupChannels[0]);
          }
        }
      }

    } else {
      dispatch(setSearchPreview(keywords))
    }
  }

  const searchResult = (filter, go = false) => {
    if (search) {
      search.cancel();
    }
    search = debounce(setSearchText, 500);
    search(filter, go)
  }

  const profile = getProfile();
  const { picture } = profile.idTokenPayload || {};

  React.useEffect(() => {
    if (profile.idTokenPayload) {
      let { organization_id } = profile;
      setCurrentOrg(organization_id);
      let { email } = profile.idTokenPayload;
      if (email) {
        client.query({
          query: gql`
            query domains{
                organization_domains_by_email(email: "${email}"){
                    domain
                    organization {
                      name
                      id
                    }
                  }
            }
          `
        }).then((result) => {
          setOrganizations(result.data.organization_domains_by_email);
        })
      }
    }
  }, [profile]);

  const onClickPopupMark = () => {
    dispatch(
      setIsTourPopStatus(true)
    );
  }

  if (!organization) return <></>;
  const orgId = organization.id;

  let isSuperAdmin = false;
  if (profile.idTokenPayload) {
    const roles = profile.idTokenPayload.roles || [];
    const hasSARole = roles.find(el => el.name == 'super-admin');
    isSuperAdmin = hasSARole ? true : false;
  };

  const ProfileMenuOptions = [
    { label: 'Profile', action: () => navigate(`/app/${orgId}/profile`) },
    { label: 'Toggle theme', action: () => dispatch(toggleTheme()) },
    { label: 'Need Help ?', action: () => window.open('https://success.catalystxl.com', '_blank') },
    ...isSuperAdmin ? [{ label: 'Manage Organizations', action: () => gatsbyNavigate(`/organizations`) }] : [],
    { label: 'Sign Out', action: () => logout(orgId) }
  ];


  console.log('isSuperAdmin', isSuperAdmin);

  return (
    <Grid
      gridMaxWidth={'unset'}
      overrides={{
        Grid: {
          style: () => ({
            background: theme.leftNavBackgroundColor,
            height: '59px',
            color: 'white',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
          }),
        },
      }}
    >
      {isBuilderPage && (
        <SearchContainer
          className={css({
            '@media (max-width: 768px)': {
              width: '75% !important',
              maxWidth: '75% !important',
              marginLeft: 0,
            }
          })}
        >
          <SearchCategory
            searchResult={searchResult}
            searchPreviewData={searchPreviewData}
            defaultTheme={defaultTheme}
          />
        </SearchContainer>
      )}
      <OptionsContainer
        className={css({
          '@media (max-width: 600px)': {
            marginRight: '15%'
          }
        })}
      >
        <Option
        style={{ marginRight: '24px' }}
        >
          <PwaAppBuildStatus organization_id={organization.id}/>
        </Option>
        <Option
          style={{ marginRight: '24px' }}
        >
          <StatefulPopover
            placement={PLACEMENT.bottom}
            overrides={{
              Body: {
                style: {
                  zIndex: 100,
                }
              }
            }}
            content={() => (
              <StatefulMenu
                size="compact"
                items={organizations.map((el) => {
                  return {
                    label: el.organization.id === currentOrg ? <b>{el.organization.name}</b> : el.organization.name,
                    id: el.organization.id
                  }
                })}
                overrides={{
                  List: { style: { width: '200px', height: '250px', } },
                  // Option: { props: { size: 'compact' } },
                }}
                onItemSelect={({ item }) => {
                  if (item.id === currentOrg) return;
                  switchToOrg(item.id);
                }}
              />
            )}
          >
            <Button kind={KIND.secondary} size={SIZE.mini} endEnhancer={() => <ChevronDown size={24} />}>{organization.name}</Button>
          </StatefulPopover>
        </Option>
        <Option>
          <StatefulPopover
            placement={PLACEMENT.bottom}
            overrides={{
              Body: {
                style: {
                  zIndex: 100,
                }
              }
            }}
            content={() => (
              <StatefulMenu
                items={ProfileMenuOptions}
                onItemSelect={({ item }) => {
                  item.action && item.action();
                }}
              />
            )}
            triggerType={TRIGGER_TYPE.hover}
            returnFocus
            autoFocus
          >
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
              <img src={picture} className={profileCss}></img>
              {isSuperAdmin && <LabelXSmall style={{color: 'white', backgroundColor: 'rgb(25, 171, 39)', paddingLeft: '4px', paddingRight: '4px', marginTop: '4px', fontSize: '8px', borderRadius: '2px'}}>SUPER ADMIN</LabelXSmall>}
            </div>
          </StatefulPopover>
        </Option>
        <Option>
          
          <Icon
            onClick={onClickPopupMark}
            className='question-mark-help-icon'
            icon={help}
            className={css({
              color: 'white',
              background: 'rgba(255,255,255,0.3)',
              borderRadius: '80%',
              padding: '2px 7px 4px',
              cursor: 'pointer',
              marginLeft: '10px'
            })}
          />
        </Option>
      </OptionsContainer>
    </Grid>
  );
};

const mapStateToProps = props => {
  let { organization, selectedGroup, selectedChannel, theme, searchPreviewData, cards, groups, decks, category, channels, questionCards } = props.appBasicControls;
  return {
    organization,
    defaultTheme: theme,
    searchPreviewData,
    cards,
    groups,
    decks,
    category,
    channels,
    questionCards,
    selectedGroup,
    selectedChannel,
  };
};
export default connect(mapStateToProps)(TopBar);
